import { styles } from "@sweeten/oreo";
import { StyleSheet } from "aphrodite";
import { formatDUNS, formatEIN, formatPhoneNumber } from "../shared";

export const sharedSS = StyleSheet.create({
  formCheckbox: {
    width: 16,
    height: 16,
    marginRight: 8,
    ":nth-child(1n) input": {
      width: 16,
      height: 16,
    },
  },
  horizontalLine: {
    margin: "24px -32px 24px",
    borderTop: `1px solid ${styles.colors.grey20}`,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        margin: "24px -16px 24px",
      },
    }),
  },
  horizontalLineModal: {
    margin: "24px -16px 0px",
    borderTop: `1px solid ${styles.colors.grey20}`,
  },
  flexCentered: {
    ...styles.center("vertical"),
  },
  flexStart: {
    display: "flex",
    alignItems: "flex-start",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    backgroundColor: styles.colors.white,
    padding: "24px 40px",
    boxSizing: "border-box",
    border: "1px solid #E9ECEF",
    borderRadius: 12,
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.04)",
    marginBottom: 32,
    width: "100%",
    ":nth-child(1n) label": {
      width: "100%",
    },
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        padding: "24px 16px",
      },
    }),
  },
  justifyContent: {
    justifyContent: "space-between",
  },
  buttonContainer: {
    marginTop: 32,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  uploadButton: {
    margin: "0px 12px 0px 0px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        margin: 0,
        marginBottom: 8,
        width: "100%",
      },
    }),
  },
  mobileButton: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
      },
    }),
  },
  removeButton: {
    borderRadius: 8,
    border: "2px solid #EFEFEF",
    backgroundColor: "#FCFCFC",
    color: styles.colors.black,
    ":hover": {
      backgroundColor: styles.colors.grey20,
    },
  },
  listEntry: {
    ...styles.center("vertical"),
    justifyContent: "space-between",
  },
  listEntryLargeText: {
    fontSize: 16,
    fontWeight: 600,
  },
  listEntrySmallText: {
    color: "#6C757D",
    fontSize: 13,
    marginTop: 4,
  },
  imageBox: {
    borderRadius: 8,
    boxSizing: "border-box",
    border: "1px dashed #979797",
    height: 400,
    width: "100%",
    cursor: "pointer",
    ...styles.center(),
  },
  image: {
    width: 220,
    height: 170,
    objectFit: "cover",
    borderRadius: 12,
    marginRight: 24,
    marginBottom: 24,
  },
  closeIcon: {
    position: "absolute",
    right: 0,
  },
  input: {
    height: 48,
    boxSizing: "border-box",
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 16,
    ":active": {
      borderColor: styles.colors.blueDark,
      outline: "none",
    },
    ":focus": {
      borderColor: styles.colors.blueDark,
      outline: "none",
    },
    border: `1px solid #ced4da`,
    borderRadius: 2,
    ...styles.center("vertical"),
    justifyContent: "space-between",
    backgroundColor: "transparent",
    outline: "none",
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  error: {
    borderColor: "#dc3545",
  },
  marginTop: {
    marginTop: 32,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginTop: 16,
      },
    }),
  },
  fieldName: {
    fontSize: 16,
    fontWeight: 600,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        fontSize: 14,
      },
    }),
  },
  tabletColumn: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
      },
    }),
  },
  avatarContainer: {
    backgroundColor: "#F7F7FD",
    width: 92,
    height: 92,
    borderRadius: 4,
    marginRight: 32,
  },
  avatar: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
    padding: "0px 10px",
  },
  header: {
    fontSize: 12,
    color: "#76808F",
    fontWeight: 500,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginTop: 20,
      },
    }),
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
  },
  rowMargin: {
    marginTop: 20,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginTop: 0,
      },
    }),
  },
  info: {
    backgroundColor: "rgb(77, 114, 214, 0.04)",
    padding: 20,
  },
  verifiedBadge: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#4D72D6",
    borderRadius: 12,
    padding: "4px 10px",
    color: "white",
    fontSize: 12,
    fontWeight: 600,
  },
});

export const phoneOnChange = (field, val) => {
  const newVal = formatPhoneNumber(val);
  field.input.onChange(newVal);
};

export const einOnChange = (field, val) => {
  const newVal = formatEIN(val);
  field.input.onChange(newVal);
};

export const dunsOnChange = (field, val) => {
  const newVal = formatDUNS(val);
  field.input.onChange(newVal);
};
