import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../components/shared";
import Cookies from "js-cookie";
import { api } from "../utils/api";

export const useAuth = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();

  const getUser = async () => {
    const token = Cookies.get("login-token");

    const settings = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (token) {
      setLoading(true);
      try {
        const res = await fetch(`${API_URL}/me`, settings);
        if (res.status === 401) {
          setLoading(false);
          return;
        }
        const data = await res.json();
        if (data.active === false) {
          Cookies.remove("login-token");
          setUser(null);
          setLoading(false);
          return
        }

        if (token.endsWith("impersonating")) {
          data.impersonating = true;
        } else {
          data.impersonating = false;
        }
        setUser(data);
        setLoading(false);
        return data;
      } catch (err) {
        console.error(err);
      }
    }
    return null;
  };

  const tempLogin = async (tempToken: string) => {
    setLoading(true);

    const response = await api({
      method: "POST",
      path: "temp_session",
      body: { tempToken },
    });

    if (response.error) {
      console.error(response.error);
      setLoading(false);
      return;
    }

    const accessToken = response.access_token;

    if (!accessToken) {
      console.error("Access token is missing from the response.");
      setLoading(false);
      return;
    }

    Cookies.set("login-token", accessToken);
    setLoading(false);
    return accessToken;
  };


  const login = async (username: string, password: string) => {
    setLoading(true);
    const settings = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ username, password }),
    };

    const res = await fetch(`${API_URL}/session`, settings);

    if (res.status === 400) {
      setError("Credentials are incorrect.");
      setLoading(false);
      return;
    }

    const data = await res.json();

    Cookies.set("login-token", data.access_token);

    setError("");
    setLoading(false);
    return data.access_token;
  };

  const logout = () => {
    Cookies.remove("login-token");
    setUser(null);
    navigate("/");
  };

  const ssoLogin = async (email: string) => {
    setLoading(true);

    const res = await fetch(`${API_URL}/sso?email=${encodeURIComponent(email)}`);
    if (res.status === 400) {
      setError("Credentials are incorrect.");
      setLoading(false);
      return;
    }

    const data = await res.json();
    setError("");
    setLoading(false);
    return data;
  };

  const ssoLoginUnsolicited = async (brand: string) => {
    setLoading(true);

    const res = await fetch(`${API_URL}/sso/unsolicited?brand=${brand}`);
    if (res.status === 400) {
      setError("Credentials are incorrect.");
      setLoading(false);
      return;
    }

    const data = await res.json();
    setError("");
    setLoading(false);
    return data;
  }

  return { error, getUser, loading, login, logout, user, tempLogin, ssoLogin, ssoLoginUnsolicited, setUser };
};
