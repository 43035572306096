import { useContext, useEffect, useState } from "react";
import { Modal, TextLink } from "@sweeten/oreo";
import { css } from "aphrodite";
import { Button } from "react-bootstrap";
import { api } from "../../utils/api";
import { AppDispatch } from "../app_provider";
import { workHistorySS } from "./WorkHistoryModal";
import { formatDateMMDDYYYY, formatToCurrency } from "../shared";
import { sharedSS } from "../CompanyDetails/shared";
import { setViewedAt } from "./notification_card";
import Loader from "../loader";

const VerifyModal = ({ onClose, project, notification, setUpdated }) => {
  const revision = notification.subject;
  const dispatch = useContext(AppDispatch);

  const revisionVerify = async () => {
    await api({
      path: `project_revision/${revision.id}/verify`,
      method: "POST",
    });

    await setViewedAt(notification);

    dispatch({
      type: "alert:show",
      payload: {
        variant: "success",
        text: `Verified request.`,
      },
    });

    setUpdated(true);

    onClose();
  };

  return (
    <Modal onClose={onClose} aphStyle={workHistorySS.modal}>
      <Modal.Header>
        {!project.verified
          ? "Would you like to verify this project?"
          : `Project verified on ${formatDateMMDDYYYY(
              notification.action_taken_at,
              false,
              true
            )}`}
      </Modal.Header>
      <Modal.Body>
        <div className={css(workHistorySS.details)}>
          <div className={css(workHistorySS.detailsTitle)}>{project.title}</div>
          <div className={css(workHistorySS.row)}>
            <div style={{ color: "#6F767E" }}>Client</div>
            <div>{project.client_name}</div>
          </div>
          <div className={css(workHistorySS.row)}>
            <div style={{ color: "#6F767E" }}>Contract amount</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>{formatToCurrency(revision.budget)}</div>
            </div>
          </div>
          <div className={css(workHistorySS.row)}>
            <div style={{ color: "#6F767E" }}>Start date</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>{formatDateMMDDYYYY(revision.start_date)}</div>
            </div>
          </div>
          <div className={css(workHistorySS.row)}>
            <div style={{ color: "#6F767E" }}>Project completion date</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>{formatDateMMDDYYYY(revision.end_date)}</div>
            </div>
          </div>
          <div className={css(workHistorySS.row)}>
            <div style={{ color: "#6F767E" }}>Opportunity ID</div>
            <div>N/A</div>
          </div>
        </div>
        {revision.notes && (
          <div className={css(sharedSS.fieldName)}>
            Additional notes
            <div style={{ fontWeight: 400 }}>{revision.notes}</div>
          </div>
        )}
      </Modal.Body>
      {!project.verified && (
        <Modal.Footer>
          <TextLink
            variant="black"
            style={{ marginRight: 32, fontWeight: "bold" }}
            onClick={onClose}
          >
            Cancel
          </TextLink>
          <Button onClick={revisionVerify}>Verify</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default (props) => {
  const [revisionProject, setRevisionProject] = useState(props.project);

  useEffect(async () => {
    if (!revisionProject) {
      const resp = await api({
        path: `projects?id=${props.notification.subject.project_id}`,
      });

      setRevisionProject(resp[0]);
    }
  }, []);

  return !revisionProject ? (
    <Loader />
  ) : (
    <VerifyModal project={revisionProject} {...props} />
  );
};
