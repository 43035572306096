import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={15}
    height={15}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M13.508 7.69c.114-.126.114-.253 0-.38L12.393 5.98c-.06-.064-.06-.127-.06-.253l.176-1.776c0-.126-.06-.317-.234-.317l-1.582-.382c-.057 0-.175-.063-.175-.126l-.82-1.522c-.058-.126-.233-.19-.41-.126l-1.465.697c-.06.064-.177.064-.235 0l-1.465-.697c-.175-.064-.292 0-.41.126l-.82 1.522c0 .063-.118.126-.176.126l-1.582.382a.428.428 0 0 0-.235.38l.175 1.775c0 .065 0 .191-.058.254L1.963 7.374c-.117.126-.117.253 0 .38l1.054 1.268c.058.065.058.127.058.255L2.9 11.05c0 .127.059.318.235.318l1.582.381c.059 0 .175.064.175.126l.82 1.521c.06.128.235.19.412.128l1.464-.698a.165.165 0 0 1 .235 0l1.464.698c.117.062.294 0 .411-.128l.82-1.52c.06-.066.117-.127.176-.127l1.58-.38c.117 0 .235-.193.235-.319l-.176-1.774c0-.064 0-.191.058-.254l1.117-1.332Z"
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M14.89 7.736c.147-.157.147-.314 0-.472l-1.427-1.65c-.075-.08-.075-.157-.075-.315l.224-2.201c0-.157-.076-.393-.299-.393l-2.026-.473c-.075 0-.225-.078-.225-.157L10.014.188c-.077-.157-.3-.236-.524-.157L7.614.896c-.076.078-.225.078-.3 0L5.44.03c-.225-.079-.375 0-.525.157l-1.05 1.887c0 .079-.15.157-.225.157l-2.026.472a.531.531 0 0 0-.3.472l.225 2.201c0 .08 0 .236-.075.315l-1.35 1.65c-.15.158-.15.315 0 .473l1.35 1.573c.075.08.075.156.075.313l-.225 2.202c0 .157.075.393.3.393l2.025.474c.075 0 .225.079.225.156l1.05 1.886c.076.157.3.236.525.157l1.875-.865a.216.216 0 0 1 .3 0l1.875.865c.15.079.375 0 .524-.157l1.049-1.886c.076-.08.15-.157.225-.157l2.025-.473c.15 0 .3-.237.3-.393L13.385 9.7c0-.079 0-.236.076-.313l1.428-1.652Z"
        fill="#FFF"
      />
      <path
        fill="#4D72D6"
        fillRule="nonzero"
        d="M6.234 10.726 3.711 8.013l.955-1.026 1.568 1.686 4.091-4.399.886 1.027z"
      />
    </g>
  </svg>
);

export default SvgComponent;
