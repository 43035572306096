import { Modal, TextLink, Button } from "@sweeten/oreo";

const DeleteModal = ({ onClose, onDelete }) => (
  <Modal onClose={onClose}>
    <Modal.Body>Are you sure that you'd like to delete this entry?</Modal.Body>
    <Modal.Footer>
      <TextLink
        variant="black"
        style={{ marginRight: 32, fontWeight: "bold" }}
        onClick={onClose}
      >
        Cancel
      </TextLink>
      <Button onClick={onDelete} data-test="delete">
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

export default DeleteModal;
