import { useContext, useEffect, useState } from "react";
import { Header, styles, TopNav } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite/no-important";
import { Dropdown, DropdownButton, Tab, Tabs } from "react-bootstrap";
import NotificationCard from "./notification_card";
import { api } from "../../utils/api";
import { getMwbeId } from "../shared";
import { UserData } from "../redirect_handler";
import Loader from "../loader";

const ss = StyleSheet.create({
  notificationsOptions: {
    width: 300,
    marginBottom: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
  notificationsOption: {
    fontWeight: 600,
    height: 40,
    lineHeight: "40px",
    borderRadius: 8,
    marginBottom: 8,
    color: "#0d1522",
    fontSize: 14,
    width: "100%",
    cursor: "pointer",
    paddingLeft: 16,
    ":hover": {
      backgroundColor: styles.colors.grey20,
    },
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        borderRadius: 0,
        marginBottom: 0,
      },
    }),
  },
  subOption: {
    padding: "8px 24px",
    cursor: "pointer",
    borderRadius: 30,
    height: 40,
  },
  subOptionSelected: {
    color: "white",
    fontWeight: "bold",
    backgroundColor: styles.colors.brandPrimary,
  },
  selected: {
    fontWeight: "bold",
    color: `${styles.colors.brandPrimary} !important`,
    backgroundColor: "#e3f4ff !important",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        ":nth-child(1n) > div > span": {
          color: styles.colors.brandPrimary,
          fontWeight: "bold",
        },
      },
    }),
  },
  rightContentContainer: {
    position: "absolute",
    left: 360,
    width: "calc(100% - 360px)",
    top: 144,
    paddingBottom: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        position: "relative",
        width: "100%",
        left: 0,
        marginTop: 24,
        top: 0,
      },
    }),
  },
  notificationsHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: 32,
    width: "100%",
    justifyContent: "space-between",
    borderBottom: `1px solid #E9ECEF`,
    paddingBottom: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
        paddingTop: 16,
      },
    }),
  },
  notificationsContainer: {
    maxWidth: 1200,
  },
  container: {
    maxWidth: 1200,
    margin: "auto",
    padding: "0px 16px",
    height: "100%",
  },
  sideTabs: {
    position: "relative",
    padding: "48px 0px",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        padding: "24px 0px 0px",
      },
    }),
  },
  stickyWrapper: {
    position: "sticky",
    top: 72,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        left: 0,
        right: 0,
        backgroundColor: "#F6FBFF",
        zIndex: styles.zIndexes.secondaryNav,
        padding: "16px 16px 0px",
        margin: "0px -16px",
      },
    }),
  },
  noNotifications: {
    textAlign: "center",
    fontSize: 16,
    color: "#4D72D6",
    marginTop: 128,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        marginTop: 32,
      },
    }),
  },
  mobile: {
    display: "none",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "block",
      },
    }),
  },
  tabMenu: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
        top: TopNav.style.mobile.height,
        zIndex: 100,
      },
    }),
  },
  tab: {
    color: "#76808F",
  },
  activeTab: {
    color: `${styles.colors.brandPrimary} !important`,
    fontWeight: "bold",
    backgroundColor: "#F5F7FD !important",
  },
  requestOptions: {
    display: "flex",
    alignItems: "center",
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: 200,
        display: "block",
        padding: "16px 0px",
      },
    }),
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.phoneStandard,
      style: {
        width: 120,
      },
    }),
  },
  desktop: {
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        display: "none",
      },
    }),
  },
});

const sortOptions = ["Newest first", "Oldest first"];
const sideTabOptions = ["All", "Unread", "Requests"];
const requestOptions = ["All", "Received", "Sent"];

const Notifications = ({ notifications, setUpdated }) => {
  const [activeTab, setActiveTab] = useState("All");
  const [activeRequestType, setActiveRequestType] = useState("All");
  const [sortType, setSortType] = useState("Newest first");

  const filterAndSortData = () => {
    let filteredNotifications = [];
    switch (activeTab) {
      case "Unread":
        filteredNotifications = notifications.filter(
          (notification) => !notification.viewed_at
        );
        break;
      case "Requests":
        if (activeRequestType === "All") {
          filteredNotifications = notifications;
          break;
        } else if (activeRequestType === "Received") {
          filteredNotifications = notifications.filter(
            (notification) => !notification.action_taken_at
          );
          break;
        } else {
          filteredNotifications = notifications.filter(
            (notification) => notification.action_taken_at
          );
          break;
        }
      default:
        filteredNotifications = notifications;
        break;
    }

    if (sortType === "Oldest first") {
      return filteredNotifications.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    }
    return filteredNotifications.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  };

  const filteredData = filterAndSortData();

  return (
    <div style={{ backgroundColor: "#F6FBFF", height: "100%" }}>
      <div className={css(ss.container)}>
        <div style={{ position: "relative", height: "100%" }}>
          <div className={css(ss.notificationsContainer)}>
            <div className={css(ss.notificationsHeader)}>
              <Header tag="h3">Notifications</Header>
            </div>
          </div>
          <div className={css(ss.sideTabs, ss.stickyWrapper)}>
            <div className={css(ss.notificationsOptions)}>
              {sideTabOptions.map((option) => (
                <div
                  className={css(
                    ss.notificationsOption,
                    activeTab === option && ss.selected
                  )}
                  onClick={() => setActiveTab(option)}
                  key={option}
                >
                  {option}
                </div>
              ))}
            </div>
            <div className={css(ss.mobile)}>
              <Tabs
                defaultActiveKey="All"
                onSelect={(key) => setActiveTab(key)}
                className={css(ss.tabMenu)}
              >
                <Tab
                  tabClassName={css(
                    ss.tab,
                    activeTab === "All" && ss.activeTab
                  )}
                  eventKey="All"
                  title="All"
                />
                <Tab
                  tabClassName={css(
                    ss.tab,
                    activeTab === "Unread" && ss.activeTab
                  )}
                  eventKey="Unread"
                  title="Unread"
                />
                <Tab
                  tabClassName={css(
                    ss.tab,
                    activeTab === "Requests" && ss.activeTab
                  )}
                  eventKey="Requests"
                  title="Requests"
                />
              </Tabs>
            </div>
          </div>
          <div id="right-content" className={css(ss.rightContentContainer)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 32,
              }}
            >
              {activeTab === "Requests" && (
                <>
                  <div className={css(ss.requestOptions, ss.desktop)}>
                    {requestOptions.map((option) => (
                      <div
                        className={css(
                          ss.subOption,
                          activeRequestType === option && ss.subOptionSelected
                        )}
                        onClick={() => setActiveRequestType(option)}
                        key={option}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                  <div className={css(ss.mobile)}>
                    <div className={css(ss.requestOptions)}>
                      <DropdownButton
                        title={activeRequestType}
                        style={{ width: "100%" }}
                      >
                        {requestOptions.map((option) => (
                          <Dropdown.Item
                            onClick={() => setActiveRequestType(option)}
                          >
                            {option}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </div>
                  </div>
                </>
              )}
              <DropdownButton
                title={`Sort by: ${sortType}`}
                style={{ width: 260, marginLeft: "auto" }}
              >
                {sortOptions.map((sortOption) => (
                  <Dropdown.Item
                    onClick={() => setSortType(sortOption)}
                    key={sortOption}
                  >
                    {sortOption}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            {filteredData.map((notification) => (
              <NotificationCard
                notification={notification}
                setUpdated={setUpdated}
                key={notification.id}
              />
            ))}
            {!filteredData.length && (
              <div className={css(ss.noNotifications)}>
                You don't have any new notifications
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default () => {
  const [data, setData] = useState(null);
  const [updated, setUpdated] = useState(false);

  useEffect(async () => {
    const resp = await api({
      path: `notifications/?hidden=false`,
    });

    setData(resp);
    setUpdated(false);
  }, [updated]);

  return !data ? (
    <Loader />
  ) : (
    <Notifications notifications={data} setUpdated={setUpdated} />
  );
};
