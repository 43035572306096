import { Modal, styles, Icon } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite/no-important";
import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useField, useForm } from "react-final-form-hooks";
import VerifiedIcon from "../../images/icons/verified";
import { api } from "../../utils/api";
import { AppDispatch } from "../app_provider";
import { sharedSS } from "../CompanyDetails/shared";
import FormInputField from "../FormComponents/input";
import Loader from "../loader";
import { determineSenderName } from "../Navbar/NotificationDropdown";
import { UserData } from "../redirect_handler";
import {
  addCommas,
  formatDateMMDDYYYY,
  formatToCurrency,
  getMwbeId,
  getWindowWidth,
  removeSpecialCharsAndSpaces,
} from "../shared";
import { setViewedAt } from "./notification_card";

export const workHistorySS = StyleSheet.create({
  horizontalLine: {
    margin: "16px 0px",
    borderTop: `1px solid ${styles.colors.grey20}`,
  },
  prompt: {
    marginBottom: 28,
  },
  details: {
    padding: 24,
    borderRadius: 12,
    backgroundColor: "rgba(244,244,244,0.5)",
    marginBottom: 32,
  },
  detailsTitle: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 8,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 600,
    padding: "16px 0px",
    borderBottom: "1px solid #CDD1D6",
  },
  modal: {
    width: 800,
    borderRadius: 16,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        width: "100%",
      },
    }),
  },
  icon: {
    cursor: "pointer",
    marginLeft: 16,
  },
});

export const verify = (notification, dispatch, description) => {
  api({
    path: `projects/${notification.subject.id}`,
    method: "PATCH",
    body: {
      verified: true,
      services: description,
    },
  });

  setViewedAt(notification);

  dispatch({
    type: "alert:show",
    payload: {
      variant: "success",
      text: `Verified request.`,
    },
  });
};

const reject = (notification, dispatch, reason) => {
  api({
    path: `projects/${notification.subject.id}/reject`,
    method: "POST",
    body: {
      rejected_reason: reason,
    },
  });

  setViewedAt(notification);

  dispatch({
    type: "alert:show",
    payload: {
      variant: "success",
      text: `Rejected request.`,
    },
  });
};

const requestRevision = (
  notification,
  dispatch,
  notes,
  mwbeId,
  userId,
  contractAmt,
  startDate,
  projectCompleteDate
) => {
  api({
    path: `project_revision`,
    method: "POST",
    body: {
      project_id: notification.subject.id,
      mwbe_id: mwbeId,
      start_date: startDate,
      end_date: projectCompleteDate,
      budget: contractAmt,
      user_id: userId,
      notes,
    },
  });

  setViewedAt(notification);

  dispatch({
    type: "alert:show",
    payload: {
      variant: "success",
      text: `Requested a revision for the project details.`,
    },
  });
};

const rejectedReasons = [
  "I was contacted about this, but not hired",
  "I never had any involvement in this project",
  "Other",
];

const WorkHistoryModal = ({
  notification,
  onClose,
  setUpdated,
  initialModalState,
}) => {
  const project = notification.subject;
  const [modalState, setModalState] = useState(initialModalState);
  const [editField, setEditField] = useState(null);
  const [contractAmt, setContractAmt] = useState({
    initial: project.budget,
    proposed: project.budget,
  });
  const [startDate, setStartDate] = useState({
    initial: project.start_date,
    proposed: project.start_date,
  });
  const [projectCompleteDate, setProjectCompleteDate] = useState({
    initial: project.end_date,
    proposed: project.end_date,
  });
  const [selectedRejectReason, setSelectedRejectReason] = useState(null);

  const { user } = useContext(UserData);
  const mwbeId = getMwbeId(user);

  const windowWidth = getWindowWidth();

  const dispatch = useContext(AppDispatch);

  const { form, handleSubmit } = useForm({
    onSubmit: (values) => {
      if (!modalState) {
        verify(notification, dispatch, values.input);
      } else if (modalState === "reject") {
        if (selectedRejectReason === "Other") {
          reject(notification, dispatch, values.input);
        } else {
          reject(notification, dispatch, selectedRejectReason);
        }
      } else {
        requestRevision(
          notification,
          dispatch,
          values.input,
          mwbeId,
          user.id,
          contractAmt.proposed,
          startDate.proposed,
          projectCompleteDate.proposed
        );
      }
      setUpdated(true);
      onClose();
    },
    validate: (values) => {
      const errors = {};

      if (!modalState && !values.input) {
        errors.input = "You must complete this field";
      }

      return errors;
    },
  });

  const input = useField("input", form);

  const [senderName, setSenderName] = useState(null);

  useEffect(async () => {
    const name = await determineSenderName(notification);
    setSenderName(name);
  }, []);

  if (!senderName) {
    return (
      <div style={{ padding: 12 }}>
        <Loader />
      </div>
    );
  }

  const buttonClass =
    windowWidth > styles.breakpoints.tabletStandard ? "btn-lg" : "btn-sm";

  const modalVals = {
    header: "Pending work history",
    prompt: (
      <div style={{ fontWeight: 500 }}>
        <span style={{ marginRight: 4 }}>
          You have a pending work history from {senderName}. Please review and
          add the work description. When you choose to add this project to your
          work history section, this project will be marked
        </span>
        <span
          className={css(sharedSS.verifiedBadge)}
          style={{ display: "inline-flex" }}
        >
          <VerifiedIcon
            style={{
              marginRight: 6,
            }}
          />
          Verified
        </span>
      </div>
    ),
    shouldShowDetails: true,
    showRejectOptions: false,
    canEdit: false,
    textAreaHeader: "Work description (required)",
    textAreaPlaceholder:
      "Please explain in one or two sentences the work your company was contracted to perform",
    ctas: (
      <>
        <button
          type="button"
          className={`btn ${buttonClass} btn-outline-danger`}
          onClick={() => setModalState("reject")}
        >
          Reject
        </button>
        <button
          type="button"
          className={`btn ${buttonClass} btn-outline-primary`}
          onClick={() => setModalState("revision")}
          style={{ margin: "0px 8px" }}
        >
          Request revision
        </button>
        <button
          type="button"
          className={`btn ${buttonClass} btn-primary`}
          onClick={handleSubmit}
        >
          Mark as verified
        </button>
      </>
    ),
  };

  if (modalState === "reject") {
    modalVals.header = "Tell us why you rejected this work history";
    modalVals.prompt = null;
    modalVals.shouldShowDetails = false;
    modalVals.showRejectOptions = true;
    modalVals.textAreaHeader = "Description";
    modalVals.textAreaPlaceholder =
      "Include some details about why you rejected this project";
    modalVals.ctas = (
      <>
        <button
          type="button"
          className={`btn ${buttonClass} btn-outline-secondary`}
          onClick={onClose}
          style={{ marginRight: 8 }}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`btn ${buttonClass} btn-outline-primary`}
          onClick={handleSubmit}
        >
          Send
        </button>
      </>
    );
  }
  if (modalState === "revision") {
    modalVals.header = "Request of revision";
    modalVals.prompt = `You are requesting for a revision of the work history from ${senderName} for the ${project.title} project. Please enter the correct info you want to revise.`;
    modalVals.canEdit = true;
    modalVals.showRejectOptions = false;
    modalVals.textAreaHeader = "Additional notes";
    modalVals.textAreaPlaceholder =
      "Include some details about what revisions you’re asking for the work history project";
    modalVals.ctas = (
      <>
        <button
          type="button"
          className={`btn ${buttonClass} btn-outline-secondary`}
          onClick={onClose}
          style={{ marginRight: 8 }}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`btn ${buttonClass} btn-outline-primary`}
          onClick={handleSubmit}
        >
          Request revision
        </button>
      </>
    );
  }

  const setEdit = (field) => {
    if (editField === field) {
      setEditField(null);
    } else {
      setEditField(field);
    }
  };

  const clearFields = () => {
    setContractAmt({
      ...contractAmt,
      proposed: contractAmt.initial,
    });
    setStartDate({
      ...startDate,
      proposed: startDate.initial,
    });
    setProjectCompleteDate({
      ...projectCompleteDate,
      proposed: projectCompleteDate.initial,
    });
  };

  return (
    <Modal onClose={onClose} aphStyle={workHistorySS.modal}>
      <Modal.Header>{modalVals.header}</Modal.Header>
      <div className={css(workHistorySS.horizontalLine)} />
      <Modal.Body>
        <div className={css([workHistorySS.prompt, workHistorySS.semiBold])}>
          {modalVals.prompt}
        </div>
        {modalVals.showRejectOptions && (
          <div>
            {rejectedReasons.map((reason) => (
              <div key="default-radio" className="mb-3">
                <Form.Check
                  style={{
                    marginBottom: 16,
                    marginLeft: 4,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Form.Check.Input
                    type="radio"
                    id={reason}
                    value={reason}
                    name="reject-reasons"
                    onClick={() => {
                      setSelectedRejectReason(reason);
                      if (reason === "Other") {
                        input.input.onChange(null);
                      } else {
                        input.input.onChange(reason);
                      }
                    }}
                  />
                  <Form.Check.Label for={reason}>{reason}</Form.Check.Label>
                </Form.Check>
              </div>
            ))}
          </div>
        )}
        {modalVals.shouldShowDetails && (
          <div className={css(workHistorySS.details)}>
            <div className={css(workHistorySS.detailsTitle)}>
              {project.title}
            </div>
            <div className={css(workHistorySS.row)}>
              <div style={{ color: "#6F767E" }}>Client</div>
              <div style={{ marginRight: modalVals.canEdit ? 34 : 0 }}>
                {project.client_name || "N/A"}
              </div>
            </div>
            <div className={css(workHistorySS.row)}>
              <div style={{ color: "#6F767E" }}>Contract amount</div>
              {editField === "contract-amt" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Group>
                    <InputGroup size="sm">
                      <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                      <Form.Control
                        size="sm"
                        value={addCommas(contractAmt.proposed)}
                        onChange={(e) => {
                          const newAmount = e.target.value.replace(/\D/g, "");
                          setContractAmt({
                            ...contractAmt,
                            proposed: newAmount,
                          });
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Button
                    onClick={() => {
                      setEdit("contract-amt");
                      setContractAmt({
                        ...contractAmt,
                        initial: removeSpecialCharsAndSpaces(
                          contractAmt.proposed
                        ),
                      });
                    }}
                    style={{ height: 31, marginLeft: 8 }}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>{formatToCurrency(contractAmt.initial) || "N/A"}</div>
                  {modalVals.canEdit && (
                    <div
                      onClick={() => {
                        setEdit("contract-amt");
                        clearFields();
                      }}
                      className={css(workHistorySS.icon)}
                    >
                      <Icon
                        name="edit"
                        size={18}
                        color={styles.colors.brandPrimary}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={css(workHistorySS.row)}>
              <div style={{ color: "#6F767E" }}>Start date</div>
              {editField === "state-date" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Group>
                    <InputGroup size="sm">
                      <Form.Control
                        size="sm"
                        value={startDate.proposed}
                        type="date"
                        onChange={(e) =>
                          setStartDate({
                            ...startDate,
                            proposed: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                  <Button
                    onClick={() => {
                      setEdit("state-date");
                      setStartDate({
                        ...startDate,
                        initial: startDate.proposed,
                      });
                    }}
                    style={{ height: 31, marginLeft: 8 }}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>{formatDateMMDDYYYY(startDate.initial) || "N/A"}</div>
                  {modalVals.canEdit && (
                    <div
                      onClick={() => {
                        setEdit("state-date");
                        clearFields();
                      }}
                      className={css(workHistorySS.icon)}
                    >
                      <Icon
                        name="edit"
                        size={18}
                        color={styles.colors.brandPrimary}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={css(workHistorySS.row)}>
              <div style={{ color: "#6F767E" }}>Project completion date</div>
              {editField === "project-completion-date" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Group>
                    <InputGroup size="sm">
                      <Form.Control
                        size="sm"
                        value={projectCompleteDate.proposed}
                        type="date"
                        onChange={(e) =>
                          setProjectCompleteDate({
                            ...projectCompleteDate,
                            proposed: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                  <Button
                    onClick={() => {
                      setEdit("project-completion-date");
                      setProjectCompleteDate({
                        ...projectCompleteDate,
                        initial: projectCompleteDate.proposed,
                      });
                    }}
                    style={{ height: 31, marginLeft: 8 }}
                  >
                    Save
                  </Button>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    {formatDateMMDDYYYY(projectCompleteDate.initial) || "N/A"}
                  </div>
                  {modalVals.canEdit && (
                    <div
                      onClick={() => {
                        setEdit("project-completion-date");
                        clearFields();
                      }}
                      className={css(workHistorySS.icon)}
                    >
                      <Icon
                        name="edit"
                        size={18}
                        color={styles.colors.brandPrimary}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={css(workHistorySS.row)}>
              <div style={{ color: "#6F767E" }}>Opportunity ID</div>
              <div style={{ marginRight: modalVals.canEdit ? 34 : 0 }}>N/A</div>
            </div>
          </div>
        )}
        {(modalState !== "reject" || selectedRejectReason === "Other") &&
          !notification.action_taken_at && (
            <FormInputField
              error={input.meta.touched && input.meta.error}
              inputProps={{
                ...input.input,
                as: "textarea",
                placeholder: modalVals.textAreaPlaceholder,
              }}
              label={modalVals.textAreaHeader}
            />
          )}
      </Modal.Body>
      {!notification.action_taken_at && (
        <Modal.Footer>{modalVals.ctas}</Modal.Footer>
      )}
    </Modal>
  );
};

export default WorkHistoryModal;
