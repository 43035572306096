import * as React from "react";
const SvgComponent = (props) => (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0V0Z" />
      <path
        d="M9 11v6l2-2M9 17l-2-2"
        stroke="#000929"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
      <path
        d="M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5"
        stroke="#000929"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
      <path
        d="M18 10c-3 0-4-1-4-4V2l8 8h-4Z"
        stroke="#000929"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
    </g>
  </svg>
);
export default SvgComponent;
