import { Form, Col, InputGroup } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite/no-important";
import { sharedSS } from "../CompanyDetails/shared";

const ss = StyleSheet.create({
  controlInput: {
    marginTop: 8,
    padding: "0px 16px",
    fontSize: 16,
    borderRadius: "4px",
    border: "1px solid #DEE2E6",
  },
  controlTextarea: {
    height: 100,
    padding: "8px 16px",
  },
  controlWithLabel: {
    marginTop: 0,
  },
});

const FormInputField = (props) => {
  const {
    error,
    errorProps,
    id,
    inputProps,
    label,
    labelProps,
    halfWidth,
    colStyle,
    noMarginTop,
    beforeElement,
    afterElement,
  } = props;

  return (
    <Col
      sm={halfWidth ? 6 : 12}
      style={colStyle}
      className={!noMarginTop && css(sharedSS.marginTop)}
    >
      <Form.Group controlId={id}>
        {label && (
          <Form.Label className={css(sharedSS.fieldName)} {...labelProps}>
            {label}
          </Form.Label>
        )}
        <InputGroup size="lg" style={{ borderRadius: 8 }}>
          {beforeElement && (
            <InputGroup.Text id="basic-addon1">{beforeElement}</InputGroup.Text>
          )}
          <Form.Control
            isInvalid={error}
            size="xl"
            className={css([
              ss.controlInput,
              inputProps.as === "textarea" && ss.controlTextarea,
              label && ss.controlWithLabel,
            ])}
            {...inputProps}
            value={inputProps.value || ""}
          />
          {afterElement && (
            <InputGroup.Text id="basic-addon1">{afterElement}</InputGroup.Text>
          )}
          <Form.Control.Feedback
            style={{ fontSize: 14 }}
            type="invalid"
            {...errorProps}
          >
            {error}
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </Col>
  );
};

export default FormInputField;
