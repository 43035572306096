import { Card, styles, Icon, EllipsisMenu, TextLink } from "@sweeten/oreo";
import { StyleSheet, css } from "aphrodite/no-important";
import { useEffect, useState } from "react";
import WorkHistoryIcon from "../../images/icons/work-history";
import VerifiedIcon from "../../images/icons/verified";
import CloseIcon from "../../images/icons/close";
import TimeIcon from "../../images/icons/time";
import { calculateTimeAgo, formatToCurrency } from "../shared";
import WorkHistoryModal from "./WorkHistoryModal";
import { api } from "../../utils/api";
import DeleteModal from "../delete_modal";
import VerifyModal from "./VerifyModal";
import Loader from "../loader";
import { determineSenderName } from "../Navbar/NotificationDropdown";
import { sharedSS } from "../CompanyDetails/shared";

const notificationSS = StyleSheet.create({
  card: {
    position: "relative",
    padding: "24px 24px 24px 40px",
    display: "flex",
    marginBottom: 32,
    width: "100%",
    borderRadius: 12,
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.04)",
    boxSizing: "border-box",
    backgroundColor: styles.colors.white,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        padding: 16,
        marginBottom: 24,
      },
    }),
  },
  blueDot: {
    width: 8,
    height: 8,
    position: "absolute",
    backgroundColor: styles.colors.brandPrimary,
    top: 48,
    left: 16,
    borderRadius: "50%",
  },
  iconContainer: {
    minWidth: 58,
    height: 58,
    backgroundColor: "#DCEED7",
    ...styles.center(),
    marginRight: 24,
    borderRadius: "50%",
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 8,
  },
  viewDetails: {
    fontSize: 14,
    color: styles.colors.brandPrimary,
    marginBottom: 24,
    cursor: "pointer",
  },
  timeAgo: {
    fontSize: 14,
    color: "#76808F",
  },
  badge: {
    display: "flex",
    alignItems: "center",
    borderRadius: 12,
    padding: "4px 10px",
    color: "white",
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 16,
  },
  verified: {
    backgroundColor: "#4D72D6",
  },
  rejected: {
    backgroundColor: "#F69797",
  },
  revisionRequested: {
    backgroundColor: "#91C97E",
  },
  ellipsis: {
    position: "absolute",
    width: 32,
    height: 32,
    borderRadius: "50%",
    ...styles.center(),
    backgroundColor: "#F8F9FA",
    top: 24,
    right: 24,
  },
  badgeAbsolute: {
    position: "absolute",
    bottom: 24,
    right: 24,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletStandard,
      style: {
        bottom: 16,
      },
    }),
  },
  body: {
    marginBottom: 8,
    ...styles.mediaQuery({
      maxWidth: styles.breakpoints.tabletSmall,
      style: {
        maxWidth: "85%",
      },
    }),
  },
});

export const setViewedAt = (notification) => {
  api({
    path: `notifications/${notification.id}`,
    method: "PATCH",
    body: {
      viewed_at: new Date(),
    },
  });
};

export const setUnviewed = (notification) => {
  api({
    path: `notifications/${notification.id}`,
    method: "PATCH",
    body: {
      viewed_at: null,
    },
  });
};

export const setHidden = (notification) => {
  api({
    path: `notifications/${notification.id}`,
    method: "PATCH",
    body: {
      hidden: true,
    },
  });
};

const determineNotificationIconData = (
  notification,
  setModalState,
  setUpdated,
  senderName
) => {
  let cardData = {};
  const { subject } = notification;

  switch (notification.type) {
    case "new_work_history":
      cardData = {
        icon: <WorkHistoryIcon />,
        header: "New pending work history",
        body: `You have a pending work history for ${formatToCurrency(
          subject.budget
        )} from ${senderName}. Please review.`,
        onClickView: () => {
          setModalState("work-history");
          setUpdated(true);
        },
        showViewDetails: true,
      };
      break;
    case "rejected_work_history":
      cardData = {
        icon: <WorkHistoryIcon />,
        header: "Rejected work history",
        body: `A work history request has been rejected from ${senderName}`,
        onClickView: () => {},
        showViewDetails: false,
      };
      break;
    case "revised_work_history":
      cardData = {
        icon: <WorkHistoryIcon />,
        header: "Revision request for work history",
        body: `${senderName} has requested a revision for a work history request.`,
        onClickView: () => setModalState("verify"),
        showViewDetails: true,
      };
      break;
    default:
      cardData = {
        icon: <WorkHistoryIcon />,
        header: "New opportunities available",
        body: (
          <div>
            There are new projects in your area. Visit the
            <TextLink
              onClick={() => (window.location.href = "/opportunity/search")}
            >
              Opportunities
            </TextLink>
            section to learn more.
          </div>
        ),
        showViewDetails: true,
      };
      break;
  }

  return cardData;
};

const NotificationCard = ({ notification, setUpdated, senderName }) => {
  const { subject } = notification;
  const [modalState, setModalState] = useState(null);
  const cardData = determineNotificationIconData(
    notification,
    setModalState,
    setUpdated,
    senderName
  );

  return (
    <Card
      aphStyle={notificationSS.card}
      style={{ backgroundColor: !notification.viewed_at ? "#DBF1FE" : "white" }}
    >
      {!notification.viewed_at && (
        <div className={css(notificationSS.blueDot)} />
      )}
      <div className={css(notificationSS.iconContainer)}>{cardData.icon}</div>
      <div>
        <div className={css(notificationSS.header)}>{cardData.header}</div>
        <div className={css(notificationSS.body)}>{cardData.body}</div>
        {cardData.showViewDetails && (
          <div
            className={css(notificationSS.viewDetails)}
            onClick={cardData.onClickView}
          >
            View details{" "}
            <Icon
              name="chevron-right"
              size={12}
              color={styles.colors.brandPrimary}
            />
          </div>
        )}
        <div className={css(notificationSS.timeAgo)}>
          {calculateTimeAgo(notification.created_at)}
        </div>
      </div>
      <div className={css(notificationSS.ellipsis)}>
        <EllipsisMenu>
          {notification.type === "revised_work_history" && (
            <EllipsisMenu.Item onClick={() => setModalState("verify")}>
              Verify
            </EllipsisMenu.Item>
          )}
          {!notification.viewed_at ? (
            <EllipsisMenu.Item
              onClick={() => {
                setViewedAt(notification);
                setUpdated(true);
              }}
            >
              Mark as read
            </EllipsisMenu.Item>
          ) : (
            <EllipsisMenu.Item
              onClick={() => {
                setUnviewed(notification);
                setUpdated(true);
              }}
            >
              Mark as unread
            </EllipsisMenu.Item>
          )}
          <EllipsisMenu.Item onClick={() => setModalState("delete")}>
            Remove
          </EllipsisMenu.Item>
        </EllipsisMenu>
      </div>
      {subject.verified && (
        <div
          className={css(
            notificationSS.badge,
            notificationSS.badgeAbsolute,
            notificationSS.verified
          )}
        >
          <VerifiedIcon
            style={{
              marginRight: 6,
            }}
          />
          Verified
        </div>
      )}
      {subject.action_taken_type === "project_rejected" && (
        <div
          className={css(
            notificationSS.badge,
            notificationSS.badgeAbsolute,
            notificationSS.rejected
          )}
        >
          <CloseIcon
            style={{
              marginRight: 6,
            }}
          />
          Rejected
        </div>
      )}
      {subject.action_taken_type === "project_revised" && (
        <div
          className={css(
            notificationSS.badge,
            notificationSS.badgeAbsolute,
            notificationSS.revisionRequested
          )}
        >
          <TimeIcon
            style={{
              marginRight: 6,
            }}
          />
          Revision requested
        </div>
      )}
      {modalState === "work-history" && (
        <WorkHistoryModal
          notification={notification}
          onClose={() => setModalState(null)}
          setUpdated={setUpdated}
        />
      )}
      {modalState === "verify" && (
        <VerifyModal
          notification={notification}
          onClose={() => setModalState(null)}
          setUpdated={setUpdated}
        />
      )}
      {modalState === "delete" && (
        <DeleteModal
          onClose={() => setModalState(null)}
          onDelete={() => {
            setHidden(notification);
            setModalState(null);
            setUpdated(true);
          }}
        />
      )}
    </Card>
  );
};

export default (props) => {
  const [senderName, setSenderName] = useState(null);

  useEffect(async () => {
    const name = await determineSenderName(props.notification);
    setSenderName(name);
  }, []);

  if (!senderName) {
    return (
      <div style={{ padding: 12 }}>
        <Loader />
      </div>
    );
  }

  return <NotificationCard senderName={senderName} {...props} />;
};
