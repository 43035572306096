import ReactDOM from "react-dom";
import { useContext, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import loadable from "@loadable/component";

import { isMobile } from "./components/shared";
import { styles } from "@sweeten/oreo";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "./vendor/muze_bootstrap/assets/css/theme.css";
import Notifications from "./components/Notifications";
import { getWindowWidth } from "./components/shared";
import * as Sentry from "@sentry/react";
import { StyleSheet, css } from "aphrodite";
import { AppState } from "./components/app_provider";

const AppProvider = loadable(() => import("./components/app_provider"));
const BusinessProfilePage = loadable(() =>
  import("./routes/BusinessProfilePage")
);
const LoginSignUp = loadable(() =>
  import("./components/Guest/LoginComponents")
);
const SearchPage = loadable(() => import("./routes/SearchPage"));
const MessagingInbox = loadable(() => import("./routes/Messaging/inbox"));
const MessagingThread = loadable(() => import("./routes/Messaging/thread"));
const Navbar = loadable(() => import("./components/Navbar"));
const SideNav = loadable(() => import("./components/SideNav"));

const ShortlistPage = loadable(() => import("./components/ShortlistPage"));
const ShortlistDetail = loadable(() =>
  import("./components/ShortlistPage/detail")
);
const CompanyDetails = loadable(() => import("./components/CompanyDetails"));
const ClaimBusiness = loadable(() => import("./components/ClaimBusiness"));
const ForgotPassword = loadable(() =>
  import("./components/Guest/Password/ForgotPassword")
);
const NewPassword = loadable(() =>
  import("./components/Guest/Password/NewPassword")
);
const BasicInfo = loadable(() =>
  import("./components/Onboarding/sections/basic_info")
);

// Onboarding routes
const GetStarted = loadable(() =>
  import("./components/Onboarding/get_started")
);
const Documents = loadable(() =>
  import("./components/Onboarding/sections/documents")
);
const Financials = loadable(() =>
  import("./components/Onboarding/sections/financials")
);
const WorkExperience = loadable(() =>
  import("./components/Onboarding/sections/work_experience")
);
const References = loadable(() =>
  import("./components/Onboarding/sections/references")
);
const UploadPhotos = loadable(() =>
  import("./components/Onboarding/sections/upload_photos")
);
const Outro = loadable(() => import("./components/Onboarding/outro"));
const RedirectHandler = loadable(() => import("./components/redirect_handler"));

// Opportunity routes
const Opportunity = loadable(() =>
  import("./components/Opportunity/wizard.js")
);
const OpportunitySearch = loadable(() =>
  import("./components/OpportunitySearch")
);
const OpportunityProfile = loadable(() =>
  import("./components/OpportunityProfile/index")
);
const OpportunityDashboard = loadable(() =>
  import("./components/Opportunity/dashboard")
);
const RequestCredentials = loadable(() =>
  import("./components/Guest/RequestCreds")
);

// Parent project routes
const MyProjects = loadable(() =>
  import("./components/ParentProject/MyProjects")
);
const ProjectReport = loadable(() =>
  import("./components/ParentProject/ProjectReport/ReportingForm/report_screen")
);
const ParentProjectWrapper = loadable(() =>
  import("./components/ParentProject/ProjectShow/wrapper")
);
const ParentProjectDashboard = loadable(() =>
  import("./components/ParentProject/Dashboard/index")
);

// Impact dashboard routes
const MyImpactDashboards = loadable(() =>
  import("./components/ImpactDashboards/index")
);
const ImpactDashboard = loadable(() =>
  import("./components/ImpactDashboards/Dashboard/index")
);
const DiversityCompanyDetails = loadable(() =>
  import("./components/ImpactDashboards/DiversityCompanyDetails/index")
);
const ImpactDashboardWrapper = loadable(() =>
  import("./components/ImpactDashboards/wrapper")
);

const YourTeam = loadable(() =>
  import("./components/ParentProject/Participants/your_team")
);
const Stakeholders = loadable(() =>
  import("./components/ParentProject/Participants/stakeholders")
);
const DataInputIndex = loadable(() =>
  import("./components/ParentProject/ProjectShow/DataInput/index")
);
const Exclusions = loadable(() =>
  import("./components/ParentProject/Exclusions/index")
);
const NotificationSettings = loadable(() =>
  import("./components/ParentProject/NotificationSettings/index")
);

const TransparencyDashboard = loadable(() =>
  import("./components/TransparencyDashboard/index")
);

const Impersonate = loadable(() => import("./components/Impersonate/index"));

const Sso = loadable(() => import("./components/Sso/index"));
const SsoCallback = loadable(() => import("./components/Sso/SsoCallback"));

const FileUpload = loadable(() => import("./components/FileUpload/index"));

const PaymentTracking = loadable(() =>
  import("./routes/PaymentTracking/index")
);

const PaymentTrackingProject = loadable(() =>
  import("./routes/PaymentTracking/project_view")
);

const AcceptPaymentsInvite = loadable(() =>
  import("./routes/PaymentTracking/accept_invite")
);

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://a5bf83993441435d9791deb09b020a38@o216007.ingest.sentry.io/4505568937902080",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
  });
}

const ss = StyleSheet.create({
  noScrollbar: {
    overflow: "hidden",
  },
});

const WithNav = ({ loggedIn }) => {
  const windowWidth = getWindowWidth();
  const isDesktop = windowWidth > styles.breakpoints.tabletStandard;
  const { preventScroll } = useContext(AppState);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <RedirectHandler>
      <Navbar loggedIn={loggedIn} />
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
        className={css(preventScroll && ss.noScrollbar)}
      >
        {isDesktop && loggedIn && <SideNav />}
        <div
          style={{
            width: isDesktop && loggedIn ? "calc(100% - 81px)" : "100%",
            marginLeft: isDesktop && loggedIn ? 81 : null,
            height: "100%",
          }}
        >
          <Outlet />
        </div>
      </div>
    </RedirectHandler>
  );
};

const WithoutNav = () => {
  const { preventScroll } = useContext(AppState);
  return (
    <RedirectHandler>
      <div
        style={{ height: "100%" }}
        className={css(preventScroll && ss.noScrollbar)}
      >
        <Outlet />
      </div>
    </RedirectHandler>
  );
};

const WithoutRender = () => {
  return <Outlet />;
};

const WithProjectWrapper = () => {
  return (
    <ParentProjectWrapper>
      <Outlet />
    </ParentProjectWrapper>
  );
};

const WithImpactDashboardWrapper = () => {
  return (
    <ImpactDashboardWrapper>
      <Outlet />
    </ImpactDashboardWrapper>
  );
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

ReactDOM.render(
  <AppProvider>
    <Helmet>
      <meta
        name="viewport"
        content={`width=device-width, initial-scale=1${
          isMobile() ? ", maximum-scale=1" : ""
        }`}
      />
    </Helmet>
    <BrowserRouter>
      <SentryRoutes>
        <Route
          path="/accept-payments-invite/:tempToken"
          element={<AcceptPaymentsInvite />}
        />
        <Route element={<WithNav loggedIn={false} />}>
          <Route path="/" element={<LoginSignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/new-password/:tempToken" element={<NewPassword />}>
            <Route path=":redirect" element={<NewPassword />} />
            <Route path="" element={<NewPassword />} />
          </Route>
          <Route path="/request-login" element={<RequestCredentials />} />
          <Route>
            <Route path="/file-upload" element={<FileUpload />} />
          </Route>
        </Route>
        <Route element={<WithNav loggedIn />}>
          <Route path="/payment-tracking" element={<PaymentTracking />} />
          <Route
            path="/payment-tracking/:id"
            element={<PaymentTrackingProject />}
          />
          <Route path="/my-lists" element={<ShortlistPage />} />
          <Route path="/my-lists/:id" element={<ShortlistDetail />} />
          <Route path="/profile/:id" element={<BusinessProfilePage />} />
          <Route path="/claim" element={<ClaimBusiness />} />

          <Route path="/onboarding" element={<GetStarted />} />
          <Route path="/onboarding/basic-information" element={<BasicInfo />} />
          <Route path="/onboarding/documents" element={<Documents />} />
          <Route path="/onboarding/financials" element={<Financials />} />
          <Route
            path="/onboarding/work-experience"
            element={<WorkExperience />}
          />
          <Route path="/onboarding/references" element={<References />} />
          <Route path="/onboarding/upload-photos" element={<UploadPhotos />} />
          <Route path="/onboarding/outro" element={<Outro />} />

          <Route path="/onboarding/:id" element={<GetStarted />} />
          <Route
            path="/onboarding/basic-information/:id"
            element={<BasicInfo />}
          />
          <Route path="/onboarding/documents/:id" element={<Documents />} />
          <Route path="/onboarding/financials/:id" element={<Financials />} />
          <Route
            path="/onboarding/work-experience/:id"
            element={<WorkExperience />}
          />
          <Route path="/onboarding/references/:id" element={<References />} />
          <Route
            path="/onboarding/upload-photos/:id"
            element={<UploadPhotos />}
          />
          <Route path="/onboarding/outro/:id" element={<Outro />} />

          <Route path="/opportunity/:id" element={<OpportunityProfile />} />
          <Route path="/opportunity/:id/edit" element={<Opportunity />} />
          <Route
            path="/opportunity/dashboard"
            element={<OpportunityDashboard />}
          />

          <Route path="/messaging/inbox" element={<MessagingInbox />} />
          <Route path="/messaging/threads/:id" element={<MessagingThread />} />

          <Route path="/details/" element={<CompanyDetails />} />
          <Route path="/details/mwbe/:id" element={<CompanyDetails />} />
          <Route
            path="/details/hiring_company/:id"
            element={<CompanyDetails />}
          />

          <Route path="/search" element={<SearchPage />} />
          <Route path="/opportunity/search" element={<OpportunitySearch />} />
          <Route path="/notifications" element={<Notifications />} />

          <Route path="/parent-project/all" element={<MyProjects />} />
          <Route element={<WithProjectWrapper />}>
            <Route
              path="/parent-project/:projectId/report/:reportType/:typeId"
              element={<ProjectReport />}
            />
            <Route
              path="/parent-project/:id/home"
              element={<ParentProjectDashboard />}
            />
            <Route
              path="/parent-project/:id/data-input/:activeTab"
              element={<DataInputIndex />}
            />
            <Route
              path="/parent-project/:projectId/participants/stakeholders/:activeTab"
              element={<Stakeholders />}
            />
            <Route
              path="/parent-project/:projectId/participants/team"
              element={<YourTeam />}
            />
            <Route
              path="/parent-project/:id/exclusions"
              element={<Exclusions />}
            />
            <Route
              path="/parent-project/:projectId/notification-settings"
              element={<NotificationSettings />}
            />
          </Route>

          <Route
            path="/impact-dashboards/all"
            element={<MyImpactDashboards />}
          />
          <Route element={<WithImpactDashboardWrapper />}>
            <Route
              path="/impact-dashboards/:id/home"
              element={<ImpactDashboard />}
            />
            <Route
              path="/impact-dashboards/:id/diversity-company/:certGroup"
              element={<DiversityCompanyDetails />}
            />
          </Route>
          <Route element={<WithProjectWrapper />}>
            <Route
              path="/parent-project/:projectId/report/:reportType/:typeId"
              element={<ProjectReport />}
            />
            <Route
              path="/parent-project/:id/home"
              element={<ParentProjectDashboard />}
            />
            <Route
              path="/parent-project/:id/data-input/:activeTab"
              element={<DataInputIndex />}
            />
            <Route
              path="/parent-project/:projectId/participants/stakeholders/:activeTab"
              element={<Stakeholders />}
            />
            <Route
              path="/parent-project/:projectId/participants/team"
              element={<YourTeam />}
            />
            <Route
              path="/parent-project/:id/exclusions"
              element={<Exclusions />}
            />
            <Route
              path="/parent-project/:projectId/notification-settings"
              element={<NotificationSettings />}
            />
          </Route>
          <Route path="/:id" element={<BusinessProfilePage />} />
        </Route>
        <Route element={<WithoutNav />}>
          <Route
            path="/community-engagement-dashboard/projects/:id"
            element={<TransparencyDashboard />}
          />
          <Route
            path="/community-engagement-dashboard/baltimore-peninsula"
            element={<TransparencyDashboard />}
          />
          <Route
            path="/baltimore-peninsula"
            element={<TransparencyDashboard />}
          />
        </Route>
        <Route>
          <Route path="/impersonate/:id" element={<Impersonate />} />
        </Route>
        <Route>
          <Route path="/sso" element={<Sso />} />
          <Route path="/sso/callback" element={<SsoCallback />} />
        </Route>
      </SentryRoutes>
    </BrowserRouter>
  </AppProvider>,
  document.getElementById("root")
);
