import * as React from "react";
const SvgComponent = (props) => (
  <svg
    width={16}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      d="M7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0Zm0 2.52c.31 0 .56.25.56.56v3.596L10.194 8.2a.555.555 0 0 1 .2.76.555.555 0 0 1-.76.202L6.78 7.516c-.021-.009-.041-.023-.061-.035A.554.554 0 0 1 6.44 7V3.08c0-.31.25-.56.56-.56Z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
export default SvgComponent;
