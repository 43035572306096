import * as React from "react";
const SvgComponent = (props) => (
  <svg
    width={16}
    height={16}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 16 16"
    {...props}
  >
    <defs>
      <path
        d="M12.471 3.529c.26.26.26.682 0 .942l-3.528 3.53 3.528 3.528a.667.667 0 0 1-.942.942L8 8.943l-3.529 3.528a.667.667 0 0 1-.942-.942L7.057 8 3.53 4.471a.667.667 0 1 1 .942-.942l3.53 3.528 3.528-3.528c.26-.26.682-.26.942 0Z"
        id="prefix__a"
        stroke="currentColor"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h16v16H0z" />
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#prefix__a" />
    </g>
  </svg>
);
export default SvgComponent;
